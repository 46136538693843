import ApiService from "@/core/services/api.service.js";

const blogService = {
  getBlogs(param) {
        var paramStr = ''
        if(param.page != undefined){
          paramStr = '?page='+(param.page+1)
        }else{
          paramStr = '?'
        }
        for (const key in param.filters) {
          if(param.filters[key] != null)
            paramStr += '&' + key + '=' + param.filters[key]
        }
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/blogs"+paramStr)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

  publish(id) {
      return  new Promise((resolve, reject) => {
        ApiService.post("api/admin/blogs/publish/"+id)
          .then(function(response) {
            if (response.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },
  getBlogById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/blogs/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createBlog(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/blogs/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateBlog(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/blogs/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteBlog(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/blogs/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    approve(id) {
      return  new Promise((resolve, reject) => {
        ApiService.post("api/admin/blogs/comment/status/"+id)
          .then(function(response) {
            if (response.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },
  replyComment(data,id) {
    return  new Promise((resolve, reject) => {
      ApiService.post("api/admin/blogs/comment/reply/"+id,data)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
},
}

export default blogService;